import React, { useState } from "react";
import { Input } from "../components/Input";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const ChangePassword = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const navigate = useNavigate();

  const updatePassword = (e) => setPassword(e.target.value);
  const updateConfirmPassword = (e) => setConfirmPassword(e.target.value);

  const createUser = () => {
    if (password == "" || confirmPassword == "") {
      toast.error("All fields are required!");
    } else {
      if (password != confirmPassword) {
        toast.error("Password must match.");
      } else {
        axios
          .post(
            "https://backend.primeautovalue.com/api.php/change-password",
            {
              password: password,
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("pav_token")}`,
              },
            }
          )
          .then((response) => {
            if (response.data.code === 1) {
              toast.success("Password Changed!");
              setPassword("");
              setConfirmPassword("");
            } else if (response.data.code === 99) {
              localStorage.removeItem("pav_token");
              navigate("/login");
            }
          })
          .catch((error) => {
            console.error("There was an error creating the user!", error);
          });
      }
    }
  };

  return (
    <div className="h-screen w-full flex flex-col justify-center items-center px-2">
      <ToastContainer />
      <div className="bg-white p-5 border max-w-[600px] rounded-xl py-16">
        <h2 className="font-bold text-4xl text-blue tracking-widest font-prodiumMiheer ">
          CREATE PASSWORD
        </h2>
        <div className="mt-5">
          <Input
            label="Password"
            placeholder={"Enter Password"}
            type="password"
            onChange={updatePassword}
            value={password}
          />
          <br />
          <br />
          <Input
            label="Confirm Password"
            placeholder={"Confirm Password"}
            type="password"
            onChange={updateConfirmPassword}
            value={confirmPassword}
          />
        </div>
        <div className="flex justify-center">
          <button
            className="px-20 py-5 text-white font-manrope rounded-xl mt-6 m-auto bg-blue"
            onClick={createUser}
          >
            Change Password
          </button>
        </div>
      </div>
      <span
        className="font-manrope text-sm mt-3"
        onClick={() => navigate("/admin")}
      >
        Back To Dashboard
      </span>
    </div>
  );
};

export default ChangePassword;
