import React from "react";
import { Navigate } from "react-router-dom";

const AuthGuard = ({ Component }) => {
  const token = localStorage.getItem("pav_token");
  const auth = token != null;

  // If authenticated, return the children component
  // Otherwise, return a Navigate component to redirect to the login page
  return auth ? <Component /> : <Navigate to="/login" />;
};

export default AuthGuard;
