import React, { useState } from "react";
import { Input } from "../components/Input";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { type } from "@testing-library/user-event/dist/type";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();

  const updateEmail = (e) => {
    setEmail(e.target.value);
  };

  const updatePassword = (e) => {
    setPassword(e.target.value);
  };

  const loginUser = () => {
    if (email == "" || password == "") {
      toast.error("All fields are required", {
        position: "top-center",
      });
    } else {
      axios
        .post("https://backend.primeautovalue.com/api.php/admin-login", {
          type: "POST",
          data: {
            email: email,
            password: password,
          },
        })
        .then((response) => {
          if (response.data.code == 1) {
            toast.success(response.data.token);
            localStorage.setItem("pav_token", response.data.token);
            navigate("/admin");
          } else {
            toast.error("Incorrect Credentials!");
          }
        });
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="h-screen w-screen bg-slate-200 flex justify-center items-center px-2">
        <div className="bg-white container xl:w-[600px] p-4 rounded-xl">
          <h2 className="font-prodiumMiheer font-bold text-blue tracking-widest text-center text-2xl lg:text-5xl mt-6">
            PRIMEAUTOVALUE
          </h2>
          <div className="mt-10">
            <Input
              label="Email Address"
              placeholder="Enter Email Address"
              value={email}
              type="email"
              onChange={updateEmail}
            />
            <div className="h-5 w-full"></div>
            <Input
              label="Password"
              placeholder="Enter Password"
              value={password}
              type="password"
              onChange={updatePassword}
            />
          </div>

          <div className="flex mt-3 md:mt-3 2xl:mt-10 flex-col items-center">
            <button
              className="px-20 py-3 text-white font-manrope rounded-xl bg-blue"
              onClick={loginUser}
            >
              Log In
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
