const Input = ({
  helpText = "",
  value = "",
  label = "",
  placeholder,
  onChange,
  type = "",
  min = "",
  max = "",
  inputClasses = "",
  labelClasses = "",
}) => {
  return (
    <>
      {label && (
        <label
          className={`${labelClasses} block text-gray-700 mb-2 font-manrope font-semibold md:text-lg 2xl:text-lg `}
        >
          {label}
        </label>
      )}
      <input
        className={`appearance-none text-sm md:text-md border font-manrope border-lightgrey rounded-lg h-[50px] w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${inputClasses}`}
        type={type}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        max={max}
      />
      {helpText && (
        <span className="absolute hidden md:flex inset-y-14 right-0 text-sm items-center pr-3 font-manrope">
          {helpText}
        </span>
      )}
    </>
  );
};

const DollarInput = ({
  helpText = "",
  value = "",
  label = "",
  placeholder,
  onChange,
  type = "",
  min = "",
  max = "",
  inputClasses = "",
  labelClasses = "",
}) => {
  return (
    <>
      {label && (
        <label
          className={`${labelClasses} block text-gray-700 mb-2 font-manrope font-semibold md:text-lg 2xl:text-lg `}
        >
          {label}
        </label>
      )}
      <div className="flex items-center relative">
        <span className="left-4 absolute">$</span>
        <input
          className={`appearance-none pl-8 text-sm md:text-md border font-manrope border-lightgrey rounded-lg h-[50px] w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${inputClasses}`}
          type={type}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          max={max}
        />
      </div>
      {helpText && (
        <span className="absolute hidden md:flex inset-y-14 right-0 text-sm items-center pr-3 font-manrope">
          {helpText}
        </span>
      )}
    </>
  );
};

const PreFilledInput = ({ helpText = "", label = "", placeholder, value }) => {
  return (
    <>
      {label && (
        <label className="block text-gray-700 mb-2 font-manrope font-semibold md:text-lg 2xl:text-lg">
          {label}
        </label>
      )}
      <input
        className="appearance-none text-md md:text-md shadow-default font-manrope rounded-lg h-[50px] w-full py-2 px-3 text-blue font-bold leading-tight focus:outline-none focus:shadow-outline"
        type="text"
        placeholder={placeholder}
        value={value}
        disabled={true}
      />
      {helpText && (
        <span className="absolute hidden md:flex inset-y-14 right-0 text-sm items-center pr-3 font-manrope">
          {helpText}
        </span>
      )}
    </>
  );
};

export { Input, PreFilledInput, DollarInput };
