import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import Home from "./Home";
import Login from "./Admin/Login";
import Dashboard from "./Admin/Dashboard";
import AdminHome from "./Admin/AdminHome";
import ViewCarDetails from "./Admin/ViewCarDetails";
import AuthGuard from "./useAuth";
import AddUser from "./Admin/AddUser";
import ToC from "./ToC";
import Privacy from "./Privacy";
import Faq from "./Faq";
import ChangePassword from "./Admin/ChangePassword";
import ThankYou from "./ThankYou";

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<Login />} />
      <Route path="/terms" element={<ToC />} />
      <Route path="/privacy" element={<Privacy />} />
      <Route path="/faq" element={<Faq />} />
      <Route path="/finish" element={<ThankYou />} />
      <Route path="/admin" element={<AuthGuard Component={Dashboard} />}>
        <Route path="" element={<AdminHome />} />
        <Route path="add-user" element={<AddUser />} />
        <Route path="change-password" element={<ChangePassword />} />
        <Route path="car-details/:id" element={<ViewCarDetails />} />
      </Route>
    </Routes>
  );
};

export default App;
