import React, { useEffect, useState } from "react";
import CarCard from "./CarCard";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import CarCardSmall from "./CarCarSmall";

const AdminHome = ({ toggleSidebar }) => {
  const [cars, setCars] = useState([]);
  const [userList, setUserList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showLatest, setShowLatest] = useState(true);
  const [showFiltered, setShowFiltered] = useState(0);
  const [selectedUser, setSelectedUser] = useState(null); // State for selected user

  const userData = jwtDecode(localStorage.getItem("pav_token"));
  const navigate = useNavigate();

  useEffect(() => {
    getList();
  }, []);

  const getUserList = () => {
    axios
      .get("https://backend.primeautovalue.com/api.php/users", {
        type: "get",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("pav_token")}`,
        },
      })
      .then((response) => {
        setUserList(response.data);
        setLoading(false);
      });
  };

  const getList = () => {
    axios
      .get("https://backend.primeautovalue.com/api.php/cars", {
        type: "get",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("pav_token")}`,
        },
      })
      .then((response) => {
        if (response.code == 99) {
          localStorage.removeItem("pav_token");
          navigate("/login");
        } else {
          setCars(response.data);
          if (userData.rank == 1) {
            getUserList();
          } else {
            setLoading(false);
          }
        }
      });
  };

  const assignCar = (e, i) => {
    axios
      .post(
        "https://backend.primeautovalue.com/api.php/assign-query/" +
          e +
          "/" +
          i,
        {
          user_id: e,
          car_id: i,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("pav_token")}`,
          },
        }
      )
      .then((response) => {
        if (response.data.code == 1) {
          toast.success("Assigned Successfully!");
        }
      });
  };

  const sortedCars = [...cars].sort((a, b) => {
    return showLatest ? b.car_id - a.car_id : a.car_id - b.car_id;
  });

  const filteredCars = sortedCars.filter((car) => {
    // First filter by status
    if (showFiltered !== 0 && car.status !== showFiltered) return false;

    // Then filter by assigned user
    if (selectedUser && car.assigned_to !== selectedUser) return false;

    return true;
  });
  return (
    <div className="container m-auto mt-4 px-4 lg:px-10">
      <ToastContainer />
      {/* Navbar */}
      <div className="w-full h-10 mb-5 flex justify-between items-center">
        <div className="flex items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-6"
            onClick={() =>
              document
                .getElementById("mobileSidebar")
                .classList.toggle("translate-x-0")
            }
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15.75 19.5 8.25 12l7.5-7.5"
            />
          </svg>
          <h2 className="font-manrope font-semibold text-2xl ml-3">
            Dashboard
          </h2>
        </div>
        <div className="flex mt-14 md:mt-0 flex-col md:flex-row">
          <form class="mr-3">
            <select
              id="countries"
              onChange={() => setShowLatest(!showLatest)}
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-full focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 pr-8 pl-3"
            >
              <option value={true} selected>
                Show Latest
              </option>
              <option value={false}>Show Oldest</option>
            </select>
          </form>
          {userData.rank < 2 ? (
            <form className="mr-3">
              <select
                id="user-select"
                onChange={(e) => setSelectedUser(Number(e.target.value))} // Set selected user ID
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-full focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 pr-8 pl-3"
              >
                <option value="">All Users</option>{" "}
                {/* Option to show all users */}
                {userList.map((user) => (
                  <option key={user.user_id} value={user.user_id}>
                    {user.full_name}
                  </option>
                ))}
              </select>
            </form>
          ) : (
            ""
          )}
          <form class="mb-10 md:mb-0">
            <select
              id="countries"
              onChange={(e) => setShowFiltered(Number(e.target.value))}
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-full focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 pr-8 pl-3"
            >
              <option value={0} selected>
                All
              </option>
              <option value={1}>Approved</option>
              <option value={2}>Declined</option>
            </select>
          </form>
        </div>
      </div>

      <section className="w-full hidden 2xl:flex 2xl:h-[750px] 2xl:mt-6 flex-wrap overflow-auto 2xl:flex-col 2xl:flex-nowrap gap-14 2xl:gap-1">
        {loading
          ? "Loading..."
          : filteredCars.map((car) => (
              <CarCard
                key={car.car_id} // Ensure you have a unique key for each car
                car={car}
                userData={userData}
                userList={userList}
                assignCar={assignCar}
              />
            ))}
      </section>
      <section className="w-full 2xl:hidden flex-wrap flex gap-x-2">
        {loading
          ? "Loading..."
          : filteredCars.map((car) => (
              <CarCardSmall
                key={car.car_id} // Ensure you have a unique key for each car
                car={car}
                userData={userData}
                userList={userList}
                assignCar={assignCar}
              />
            ))}
      </section>
    </div>
  );
};

export default AdminHome;
