import React from "react";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <div className="w-full bg-grey pt-10" id="footer">
      <h2
        className=" text-center text-xl md:text-4xl  font-bold font-prodiumMiheer tracking-widest "
        onClick={() => navigate("/")}
      >
        PRIMEAUTOVALUE
      </h2>
      <div className="flex justify-center mt-4 flex-wrap">
        <span
          className="text-sm lg:text-2xl font-manrope font-semibold mr-4 cursor-pointer text-center md:mr-10 text-blue"
          onClick={() => navigate("/")}
        >
          Home
        </span>
        <span
          className="text-sm lg:text-2xl font-manrope font-semibold mr-4 cursor-pointer text-center md:mr-10 text-blue"
          onClick={() => navigate("/faq")}
        >
          FAQ
        </span>
        <span
          className="text-sm lg:text-2xl font-manrope font-semibold mr-4 cursor-pointer md:mr-10 text-blue"
          onClick={() => navigate("/terms")}
        >
          Terms & Condition
        </span>
        <span
          className="text-sm lg:text-2xl font-manrope font-semibold mr-4 cursor-pointer md:mr-10 text-blue"
          onClick={() => navigate("/privacy")}
        >
          Privacy Policy
        </span>
      </div>
      <div className="flex mt-10">
        <div className="flex-1 h-10 bg-black rounded-tr-full"></div>
        <div className="font-manrope text-xs mt-3 mx-4">
          © 2024 PRIMEAUTOVALUE
        </div>
        <div className="flex-1 h-10 bg-black rounded-tl-full"></div>
      </div>
    </div>
  );
};

export default Footer;
