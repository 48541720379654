import React, { useEffect, useState } from "react";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";

import TermsBg from "./images/privacy_bg.png";

const Privacy = () => {
  const [mainHeight, setMainHeight] = useState(0);

  useEffect(() => {
    const updateMainHeight = () => {
      const navbar = document.getElementById("navbar").offsetHeight;
      const footer = document.getElementById("footer").offsetHeight;

      // Calculate the main height: 100vh - navbarHeight - footerHeight
      const height = window.innerHeight - navbar - footer;
      setMainHeight(height);
    };

    // Set initial height
    updateMainHeight();

    // Update height on window resize
    window.addEventListener("resize", updateMainHeight);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", updateMainHeight);
    };
  }, []);

  return (
    <>
      <div className="flex flex-col justify-between">
        <Navbar />
        {/* Use inline style for dynamic height */}
        <div style={{ height: `${mainHeight}px` }} className="w-full flex">
          <div
            className="w-1/2 h-full bg-cover bg-center bg-no-repeat hidden lg:block relative"
            style={{ backgroundImage: `url(${TermsBg})` }}
          >
            <h1 className="text-white font-prodiumMiheer lg:text-6xl 2xl:text-7xl capitalize tracking-widest absolute bottom-10 left-10">
              PRIVACY
              <br />{" "}
              <span className="lg:text-6xl 2xl:text-7xl capitalize">
                POLICY
              </span>
            </h1>
          </div>
          <div className="w-2/2 flex justify-center items-center">
            <div
              className="container px-10 py-10 overflow-auto"
              style={{ height: `${mainHeight - 100}px` }}
            >
              <h2 className="font-manrope font-bold text-3xl">
                Privacy Policy
              </h2>
              <p className="font-manrope mt-5 text-sm mb-5 max-w-[800px]">
                1. Introduction
                <br />
                Prime Auto Value is committed to protecting your privacy. This
                Privacy Policy explains how we collect, use, disclose, and
                safeguard your information when you visit our website.
                <br />
                <br />
                2. Information We Collect
                <br />
                We collect information that you provide directly to us,
                including:
                <br />
                <ul>
                  <li>
                    Personal details such as your name, email address, and
                    contact information. Vehicle information including VIN
                    number, make, model, year, and pictures. Any other
                    information you voluntarily provide through our forms or
                    inquiries.
                  </li>
                </ul>
                <br /> 3. How We Use Your Information
                <br />
                We use the information we collect to:
                <br />
                Provide, operate, and maintain our services. Process your car
                valuation and inquiries. Improve, personalize, and expand our
                services. Communicate with you, including for customer service,
                support, and promotional purposes. <br />
                <br /> 4. Sharing Your Information
                <br />
                We may share your information with third-party service providers
                for the purpose of providing our services, such as car
                valuations and marketing. We do not sell or rent your personal
                information to third parties.
                <br />
                <br />
                5. Security of Your Information
                <br />
                We take appropriate security measures to protect your personal
                information from unauthorized access, alteration, disclosure, or
                destruction. However, no method of transmission over the
                internet or electronic storage is completely secure.
                <br />
                <br />
                6. Cookies and Tracking Technologies
                <br />
                Our website may use cookies and similar tracking technologies to
                enhance user experience and analyze website traffic. You can
                choose to disable cookies through your browser settings, but
                this may affect your use of certain features on our website.
                <br />
                <br />
                7. Your Privacy Rights
                <br />
                Depending on your location, you may have certain rights
                regarding your personal information, such as the right to
                access, update, or delete your data. To exercise these rights,
                please contact us at info@primeautovalue.com.
                <br />
                <br />
                8. Changes to This Privacy Policy
                <br />
                We may update this Privacy Policy from time to time. Any changes
                will be posted on this page with an updated “Last Updated” date.
                <br />
                <br />
                9. Contact Us
                <br />
                If you have any questions or concerns about this Privacy Policy,
                please contact us at info@primeautovalue.com.
              </p>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Privacy;
