import React from "react";
import DemoImage from "../images/demo_image.png";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

const CarCardSmall = ({ car, userData, userList, assignCar }) => {
  let carImage = car.photos.split(",");
  const navigate = useNavigate();

  function formatDate(dateString) {
    const date = new Date(dateString);

    const options = { year: "numeric", month: "short", day: "numeric" };
    const formattedDate = date.toLocaleDateString("en-US", options);

    // Extract day and format it with suffix
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.getFullYear();

    // Function to get the suffix for the day
    function getDaySuffix(day) {
      if (day > 3 && day < 21) return "th"; // Catch 11th-13th
      switch (day % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    }

    return `${day}${getDaySuffix(day)} ${month} ${year}`;
  }

  return (
    <>
      <div
        className="w-full md:w-[350px] border py-5 flex flex-col items-center justify-center rounded-2xl mt-4 "
        onClick={() => navigate(`car-details/${car.car_id}`)}
      >
        <div
          className="w-[310px] h-[210px] bg-cover bg-center rounded-2xl"
          style={{
            backgroundImage: `url(https://backend.primeautovalue.com/uploads/${carImage[0]})`,
          }}
        ></div>
        <div className="w-full px-5 mt-2">
          <h4 className="font-manrope text-2xl font-bold">
            {car.year} {car.model} {car.make}
          </h4>
        </div>
        <div className="w-full px-5  flex justify-between items-center mb-3">
          <h4 className="font-manrope text-sm font-light">{car.full_name}</h4>
          <span className="bg-[#EBEBEB] px-2 text-[#A0A0A0] text-xs font-manrope font-bold rounded-full 2xl:mt-0">
            {car.vin}
          </span>
        </div>
        {/* <hr className="w-full text-left" />
        <div className="w-full px-5 pt-3">
          <span className="text-[#277E08] font-manrope font-bold text-sm"></span>
        </div> */}
      </div>
    </>
  );
};

export default CarCardSmall;
