import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const Navbar = () => {
  const [showMobileNav, setShowMobileNav] = useState(false);

  const navigate = useNavigate();

  return (
    <>
      <div className="w-full h-20 bg-black">
        <div
          id="navbar"
          className="container m-auto h-full flex items-center justify-between px-4 md:px-0"
        >
          <div
            className="text-white font-prodiumMiheer text-md md:text-2xl"
            onClick={() => navigate("/")}
          >
            PRIMEAUTOVALUE
          </div>
          <div className="md:flex  items-center hidden">
            <div className="flex">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="16"
                viewBox="0 0 21 16"
                fill="none"
              >
                <path
                  d="M2.23657 16C1.68657 16 1.21591 15.8043 0.824572 15.413C0.433239 15.0217 0.237239 14.5507 0.236572 14V2C0.236572 1.45 0.432572 0.979333 0.824572 0.588C1.21657 0.196666 1.68724 0.000666667 2.23657 0H18.2366C18.7866 0 19.2576 0.196 19.6496 0.588C20.0416 0.98 20.2372 1.45067 20.2366 2V14C20.2366 14.55 20.0409 15.021 19.6496 15.413C19.2582 15.805 18.7872 16.0007 18.2366 16H2.23657ZM10.2366 9L18.2366 4V2L10.2366 7L2.23657 2V4L10.2366 9Z"
                  fill="white"
                />
              </svg>
              <span className="text-white text-sm cursor-pointer font-manrope mr-12 hover:text-blue ml-3">
                INFO@PRIMEAUTOVALUE.COM
              </span>
            </div>
          </div>
          <div
            className="block md:hidden"
            onClick={() => setShowMobileNav(!showMobileNav)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="white"
              class="size-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
              />
            </svg>
          </div>
        </div>
      </div>

      {/* GAP */}
      <div
        className={` absolute w-[300px] h-[60px] rounded-lg border-lightgrey bg-black z-10 top-[84px] right-3 ${
          showMobileNav ? "block" : "hidden"
        }`}
      >
        <div className="flex flex-col h-full justify-center items-center px-10 mt-2">
          <div className="flex flex-col w-full">
            <span className="text-white text-sm cursor-pointer font-manrope mr-4 mb-3 hover:text-blue">
              INFO@PRIMEAUTOVALUE.COM
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
