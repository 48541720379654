import React, { useEffect, useState } from "react";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import BG from "./images/thankyou_bg.png";
import TermsBg from "./images/privacy_bg.png";
import { useNavigate } from "react-router-dom";

const ThankYou = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="flex flex-col justify-between">
        <Navbar />
        <div
          className="container mt-40 p-4 mb-40 rounded-xl m-auto bg-center bg-cover"
          style={{ backgroundImage: `url(${BG})` }}
        >
          <div className="w-[90%] m-auto p-6 lg:p-10 bg-white flex flex-col items-center justify-center rounded-3xl">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="37"
              height="37"
              viewBox="0 0 37 37"
              fill="none"
            >
              <g clip-path="url(#clip0_1205_2890)">
                <path
                  d="M0.214977 33.3633L11.5968 23.1042L2.96191 14.4692C2.50806 14.0154 2.53195 13.3585 3.11717 12.9644L16.8279 3.64872C17.9745 2.86047 18.8702 2.86047 20.0048 3.64872L33.7156 12.9644C34.3008 13.3585 34.3127 14.0273 33.8828 14.4692L25.2359 23.1042L36.6297 33.3633C36.7611 32.8498 36.8328 32.2646 36.8328 31.6196V15.771C36.8328 13.5018 36.4744 12.5225 34.8502 11.4237L21.3783 2.16777C19.2046 0.674872 17.6281 0.674872 15.4545 2.16777L1.98256 11.4237C0.370238 12.5225 0 13.5018 0 15.771V31.6196C0 32.2646 0.0716588 32.8498 0.214977 33.3633ZM4.64589 36.2177H32.1988C33.835 36.2177 34.8979 35.7758 35.6384 35.0473L20.5423 21.456C19.8376 20.823 19.1569 20.5364 18.4164 20.5364C17.6878 20.5364 16.9951 20.823 16.2905 21.456L1.20626 35.0234C1.94674 35.7639 3.00968 36.2177 4.64589 36.2177Z"
                  fill="#3155FF"
                />
              </g>
              <defs>
                <clipPath id="clip0_1205_2890">
                  <rect
                    width="37"
                    height="35.4354"
                    fill="white"
                    transform="translate(0 0.782227)"
                  />
                </clipPath>
              </defs>
            </svg>
            <h1 className="font-manrope text-4xl font-bold mt-3">Thank You</h1>
            <p className="text-[#959595] font-manrope text-center">
              We will reach back to you shortly,...
            </p>
            <button
              className="px-12 py-5 mt-5 text-white font-manrope  rounded-xl bg-blue"
              onClick={() => navigate("/")}
            >
              Back to home
            </button>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default ThankYou;
