import React, { useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

const DashboardIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
    >
      <path
        d="M7.25024 19.2502H17.7502C18.8548 19.2502 19.7502 18.3548 19.7502 17.2502V9.75025L12.5002 4.75024L5.25024 9.75025V17.2502C5.25024 18.3548 6.14568 19.2502 7.25024 19.2502Z"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.2496 15.7493C10.2496 14.6447 11.1451 13.7493 12.2496 13.7493H12.7496C13.8542 13.7493 14.7496 14.6447 14.7496 15.7493V19.2493H10.2496V15.7493Z"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

const AddUserIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
    >
      <path
        d="M5.5 7.06087L12 12.8109L18.5 7.06087M4.75 8.31087C4.75 7.2063 5.64543 6.31087 6.75 6.31087H17.25C18.3546 6.31087 19.25 7.2063 19.25 8.31087V16.8109C19.25 17.9155 18.3546 18.8109 17.25 18.8109H6.75C5.64543 18.8109 4.75 17.9155 4.75 16.8109V8.31087Z"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

const ChangePass = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18 14.5609C20.2091 14.5609 22 12.77 22 10.5609C22 8.35173 20.2091 6.56087 18 6.56087C15.7909 6.56087 14 8.35173 14 10.5609C14 12.77 15.7909 14.5609 18 14.5609ZM18 15.5609C20.7614 15.5609 23 13.3223 23 10.5609C23 7.79944 20.7614 5.56087 18 5.56087C16.1493 5.56087 14.5334 6.56636 13.6689 8.06087H11H2.25C1.83579 8.06087 1.5 8.39665 1.5 8.81087V11.3109C1.5 11.7251 1.83579 12.0609 2.25 12.0609H3.64286V14.3109C3.64286 14.7251 3.97864 15.0609 4.39286 15.0609H5.60714C6.02136 15.0609 6.35714 14.7251 6.35714 14.3109V12.0609H7.07143V14.3109C7.07143 14.7251 7.40722 15.0609 7.82143 15.0609H9.03571C9.44993 15.0609 9.78571 14.7251 9.78571 14.3109V12.0609H13.2289C13.866 14.0894 15.7612 15.5609 18 15.5609ZM11 9.06087H13.2289C13.0802 9.53439 13 10.0383 13 10.5609C13 10.7296 13.0084 10.8964 13.0247 11.0609H9.53571C9.1215 11.0609 8.78571 11.3967 8.78571 11.8109V14.0609H8.07143V11.8109C8.07143 11.3967 7.73564 11.0609 7.32143 11.0609H6.10714C5.69293 11.0609 5.35714 11.3967 5.35714 11.8109V14.0609H4.64286V11.8109C4.64286 11.3967 4.30707 11.0609 3.89286 11.0609H2.5V9.06087H11ZM16.5 10.5609C16.5 9.73244 17.1716 9.06087 18 9.06087C18.8284 9.06087 19.5 9.73244 19.5 10.5609C19.5 11.3893 18.8284 12.0609 18 12.0609C17.1716 12.0609 16.5 11.3893 16.5 10.5609ZM18 8.06087C16.6193 8.06087 15.5 9.18016 15.5 10.5609C15.5 11.9416 16.6193 13.0609 18 13.0609C19.3807 13.0609 20.5 11.9416 20.5 10.5609C20.5 9.18016 19.3807 8.06087 18 8.06087Z"
        fill="white"
      />
    </svg>
  );
};

const Logout = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="25"
      viewBox="0 0 23 25"
      fill="none"
    >
      <path
        d="M5.5 21.5609C4.95 21.5609 4.47933 21.3652 4.088 20.9739C3.69667 20.5825 3.50067 20.1115 3.5 19.5609V5.56087C3.5 5.01087 3.696 4.5402 4.088 4.14887C4.48 3.75753 4.95067 3.56153 5.5 3.56087H12.5V5.56087H5.5V19.5609H12.5V21.5609H5.5ZM16.5 17.5609L15.125 16.1109L17.675 13.5609H9.5V11.5609H17.675L15.125 9.01087L16.5 7.56087L21.5 12.5609L16.5 17.5609Z"
        fill="white"
      />
    </svg>
  );
};

const Dashboard = () => {
  const userData = jwtDecode(localStorage.getItem("pav_token"));
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const navigate = useNavigate();

  const toggleSidebar = () => setSidebarOpen(!sidebarOpen);

  return (
    <div className="flex h-screen">
      {/* Side Bar */}
      <div
        id="mobileSidebar"
        className={`h-full w-[300px] flex absolute transform ${
          sidebarOpen ? "translate-x-0" : "-translate-x-full"
        }  lg:relative lg:translate-x-0  bg-black top-0 flex-col md:flex`}
      >
        <div className="w-full h-20 flex justify-center items-center">
          <h1 className="text-white font-prodiumMiheer font-bold text-2xl">
            PRIMEAUTOVALUE
          </h1>
          <div className="text-white lg:hidden" onClick={toggleSidebar}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="size-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </div>
        </div>

        <div className="flex-1 flex flex-col">
          <div className="mt-4">
            <div
              className="bg-[#252525] w-[250px] m-auto py-3 px-5 rounded-lg cursor-pointer flex"
              onClick={() => navigate("/admin")}
            >
              {<DashboardIcon />}
              <span className="text-white font-manrope ml-2">DASHBOARD</span>
            </div>
          </div>

          <div className="mt-auto mb-10">
            <div className="border w-[250px] m-auto rounded-full px-3 py-2 flex items-center mb-4">
              <div className="h-[38px] w-[38px] bg-grey rounded-full mr-2"></div>
              <div className="flex flex-col">
                <span className="text-white font-manrope font-bold">
                  {userData.name}
                </span>
                <span className="text-white font-manrope text-sm">
                  {userData.email}
                </span>
              </div>
            </div>
            <div className="w-[250px] m-auto">
              <span className="text-[#495057] font-manrope text-sm font-semibold">
                SETTINGS
              </span>
              {userData.rank == 1 ? (
                <div className="">
                  <div
                    className="bg-[#252525] w-[250px] m-auto py-3 px-5 rounded-lg cursor-pointer flex"
                    onClick={() => {
                      navigate("/admin/add-user");
                    }}
                  >
                    {<AddUserIcon />}
                    <span className="text-white font-manrope ml-3">
                      ADD USER
                    </span>
                  </div>
                </div>
              ) : (
                ""
              )}
              <div className="mt-2">
                <div
                  className="bg-[#252525] w-[250px] m-auto py-3 px-5 rounded-lg cursor-pointer flex"
                  onClick={() => {
                    navigate("/admin/change-password");
                  }}
                >
                  {<ChangePass />}
                  <span className="text-white font-manrope ml-3">
                    CHANGE PASSWORD
                  </span>
                </div>
              </div>
              <div className="mt-2">
                <div
                  className="bg-[#252525] w-[250px] m-auto py-3 px-5 rounded-lg cursor-pointer flex"
                  onClick={() => {
                    localStorage.removeItem("pav_token");
                    navigate("/login");
                  }}
                >
                  {<Logout />}
                  <span className="text-white font-manrope ml-3">LOG OUT</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Outlet */}
      <div className="flex-1 h-screen bg-white">
        <Outlet toggleSidebar={toggleSidebar} />
      </div>
    </div>
  );
};

export default Dashboard;
