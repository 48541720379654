import React, { useEffect, useState } from "react";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";

import TermsBg from "./images/privacy_bg.png";

const Faq = () => {
  const [mainHeight, setMainHeight] = useState(0);
  const [openIndex, setOpenIndex] = useState(null);

  const faqs = [
    {
      question: "How does Prime Auto Value work?",
      answer:
        "Prime Auto Cash allows you to quickly and easily sell your car online. Just enter your Vehicle Identification Number (VIN), fill out a small form with your car’s details, upload pictures of the vehicle, and send us an inquiry. We’ll review your submission and provide the best price offer for your car.",
    },
    {
      question: "What is a VIN, and where can I find it?",
      answer:
        "A VIN (Vehicle Identification Number) is a unique code assigned to every vehicle. It can typically be found on the dashboard near the windshield, inside the driver’s side door, or in your vehicle’s registration documents.",
    },
    {
      question: "What kind of vehicles do you buy?",
      answer:
        "We purchase a wide range of vehicles, including cars, trucks, SUVs, and vans, regardless of condition. Whether your vehicle is new, used, or damaged, you can submit an inquiry.",
    },
    {
      question: "Do I need to bring my car for an inspection?",
      answer:
        "After you submit your vehicle details and pictures, we will provide an initial offer. If you're happy with the estimate, we may arrange a physical inspection or review additional details to finalize the offer.",
    },
    {
      question: "How long does it take to get an offer?",
      answer:
        "Once you submit your vehicle’s information, we usually provide an offer within 24-48 hours. Our team works to get you the best price as quickly as possible.",
    },
    // Add more FAQs as needed
  ];

  const toggleFaq = (e) => {
    setOpenIndex(e == openIndex ? 0 : e);
  };

  useEffect(() => {
    const updateMainHeight = () => {
      const navbar = document.getElementById("navbar").offsetHeight;
      const footer = document.getElementById("footer").offsetHeight;

      // Calculate the main height: 100vh - navbarHeight - footerHeight
      const height = window.innerHeight - navbar - footer;
      setMainHeight(height);
    };

    // Set initial height
    updateMainHeight();

    // Update height on window resize
    window.addEventListener("resize", updateMainHeight);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", updateMainHeight);
    };
  }, []);

  return (
    <>
      <div className="flex flex-col justify-between">
        <Navbar />
        {/* Use inline style for dynamic height */}
        <div
          style={{ height: `${mainHeight}px` }}
          className="w-full flex overflow-auto"
        >
          <div
            className="w-1/2 h-full bg-cover bg-center bg-no-repeat hidden lg:block relative"
            style={{ backgroundImage: `url(${TermsBg})` }}
          >
            <h1 className="text-white font-prodiumMiheer lg:text-6xl 2xl:text-7xl capitalize tracking-widest absolute bottom-10 left-10">
              FAQ
              <br />{" "}
            </h1>
          </div>
          <div className="w-full lg:w-1/2 flex justify-center items-center px-2 lg:px-10">
            <div className="container px-2 lg:px-10 py-10  w-full m-auto">
              {faqs.map((faq, index) => (
                <Card
                  data={faq}
                  toggleFaq={toggleFaq}
                  openIndex={openIndex}
                  index={index}
                />
              ))}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

const Card = ({ data, toggleFaq, openIndex, index }) => {
  return (
    <div
      className={`border ${
        openIndex === index ? "border-[#3155FF]" : "border"
      } rounded-lg px-10 py-1 mb-5 cursor-pointer`}
    >
      <div
        className="flex items-center justify-between"
        onClick={() => toggleFaq(index)}
      >
        <p className="font-manrope font-bold text-xl">{data.question}</p>
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="82"
            height="84"
            viewBox="0 0 82 84"
            fill="none"
          >
            <g filter="url(#filter0_d_946_2455)">
              <ellipse
                cx="41.1373"
                cy="37.0729"
                rx="24.5811"
                ry="25.3309"
                fill="white"
              />
            </g>
            <path
              d="M38.1196 45.3295L46.0956 37.0729L38.1196 28.8163"
              stroke="#3155FF"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <defs>
              <filter
                id="filter0_d_946_2455"
                x="0.556152"
                y="0.742065"
                width="81.1622"
                height="82.6617"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dy="5" />
                <feGaussianBlur stdDeviation="8" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0.0323264 0 0 0 0 0.0598209 0 0 0 0 0.204167 0 0 0 0.06 0"
                />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow_946_2455"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow_946_2455"
                  result="shape"
                />
              </filter>
            </defs>
          </svg>
        </div>
      </div>
      {openIndex === index ? (
        <p className="mb-9 font-manrope">{data.answer}</p>
      ) : (
        ""
      )}
    </div>
  );
};

const Opened = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="52"
      viewBox="0 0 50 52"
      fill="none"
    >
      <ellipse
        cx="25.1373"
        cy="26.1407"
        rx="24.5811"
        ry="25.3309"
        fill="#3155FF"
      />
      <path
        d="M17.1251 23.031L25.1373 31.2503L33.1496 23.031"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

const Closed = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="82"
      height="84"
      viewBox="0 0 82 84"
      fill="none"
    >
      <g filter="url(#filter0_d_946_2455)">
        <ellipse
          cx="41.1373"
          cy="37.0729"
          rx="24.5811"
          ry="25.3309"
          fill="white"
        />
      </g>
      <path
        d="M38.1196 45.3295L46.0956 37.0729L38.1196 28.8163"
        stroke="#3155FF"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <defs>
        <filter
          id="filter0_d_946_2455"
          x="0.556152"
          y="0.742065"
          width="81.1622"
          height="82.6617"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="5" />
          <feGaussianBlur stdDeviation="8" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0323264 0 0 0 0 0.0598209 0 0 0 0 0.204167 0 0 0 0.06 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_946_2455"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_946_2455"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default Faq;
