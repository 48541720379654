import { useEffect } from "react";
import logo from "./logo.svg";
import "./App.css";
import Navbar from "./components/Navbar";

import EasyOne from "./images/easy_one.png";
import EasyTwo from "./images/easy_two.png";
import EasyThree from "./images/easy_three.png";

import heroBG from "./images/hero_bg.png";
import { Input, PreFilledInput, DollarInput } from "./components/Input";
import Footer from "./components/Footer";
import { useState } from "react";
import close from "./images/close.png";
import axios from "axios";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

import HomeNewBg from "./images/bg_new_new.jpg";
import Marquee from "react-fast-marquee";

function Home() {
  const [stage, setStage] = useState(0);
  const [vehicleData, setVehicleData] = useState({});
  const [sessionId, setSessionId] = useState(null);

  const updateVehicleData = (e) => {
    setVehicleData(e);
  };

  const updateStage = (e) => {
    setStage(e);
  };

  const updateSession = (e) => {
    setSessionId(e);
  };

  return (
    <>
      <div className="flex flex-col justify-between">
        <ToastContainer />
        <Navbar />

        <div
          className={`${
            stage == 0 ? "hidden" : "flex"
          } w-full  items-center justify-center mt-10 mb-10`}
        >
          <div className="flex items-center w-[400px] gap-x-3 px-2">
            <div className="flex flex-col justify-center items-center">
              <span
                className={`bg-[#ECEDED] w-[60px] rounded-full font-manrope text-center text-sm py-1 text-[#B8B6B6] font-bold`}
              >
                1
              </span>
              <span
                className={`text-[#7B7B7B] font-manrope font-bold text-sm mt-1`}
              >
                Vehicle
              </span>
            </div>
            <div className="h-[0.5px] bg-black flex-1"></div>
            <div className="flex flex-col justify-center items-center">
              <span
                className={` ${
                  stage == 1
                    ? "bg-[#3155FF] text-white"
                    : "bg-[#ECEDED] text-[#B8B6B6]"
                } w-[60px] rounded-full font-manrope text-center text-sm py-1  font-bold`}
              >
                2
              </span>
              <span
                className={`text-[#7B7B7B] font-manrope font-bold text-sm mt-1`}
              >
                Information
              </span>
            </div>
            <div className="h-[0.5px] bg-black flex-1"></div>
            <div className="flex flex-col justify-center items-center">
              <span
                className={` ${
                  stage == 2
                    ? "bg-[#3155FF] text-white"
                    : "bg-[#ECEDED] text-[#B8B6B6]"
                } w-[60px] rounded-full font-manrope text-center text-sm py-1  font-bold`}
              >
                3
              </span>
              <span
                className={`text-[#7B7B7B] font-manrope font-bold text-sm mt-1`}
              >
                Extra Info
              </span>
            </div>
          </div>
        </div>
        {stage == 0 ? (
          <FirstStep
            updateVehicleData={updateVehicleData}
            updateStage={updateStage}
          />
        ) : stage == 1 ? (
          <SecondStep
            vehicleData={vehicleData}
            updateStage={updateStage}
            updateSession={updateSession}
          />
        ) : (
          <ThirdStep sessionId={sessionId} />
        )}
        <div className="mt-10 mb-10">
          <h2 className="text-center font-bold font-podiumbold text-6xl tracking-widest">
            MAKE IT <span className="text-[#0024D0]">EASY</span>
          </h2>
          <p className="text-center font-podiumbold tracking-widest text-xl">
            HOW IT WORKS, IT&lsquo;S EASY.
          </p>

          <div className="m-auto xl:w-[1100px] mt-4 mb-10  xl:h-[400px] relative flex justify-center items-center">
            <div className="h-[1px] w-full border-dashed border hidden xl:block"></div>
            <div className="w-full xl:absolute flex xl:justify-between flex-col xl:flex-row justify-center items-center xl:mt-[42px]">
              <div className="w-[250px] md:w-[320px]">
                <img src={EasyOne} width={300} />
                <h4 className="font-manrope font-bold text-xl text-center">
                  Get your offer
                </h4>
              </div>
              <div className="w-[250px] md:w-[320px]">
                <img src={EasyTwo} width={300} />
                <h4 className="font-manrope font-bold text-xl text-center">
                  Arrange pick up or drop off
                </h4>
              </div>
              <div className="w-[250px] md:w-[320px]">
                <img src={EasyThree} width={300} />
                <h4 className="font-manrope font-bold text-xl text-center">
                  Get paid!
                </h4>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

const ThirdStep = ({ sessionId }) => {
  const [vCondition, setVCondition] = useState(5);
  const [replacing, setReplacing] = useState("");
  const [payoff, setPayoff] = useState("");
  const [damageHistory, setDamageHistory] = useState("No Previous Damage");
  const [repairs, setRepairs] = useState("");
  const [details, setDetails] = useState("");

  const [bankName, setBankName] = useState("");
  const [amount, setAmount] = useState("");

  const handleSliderChange = (event) => {
    setVCondition(event.target.value); // Update state with slider value
  };

  const updateBankName = (e) => {
    setBankName(e.target.value);
  };

  const updateAmount = (e) => {
    setAmount(e.target.value);
  };

  return (
    <div className="w-full bg-white py-6 mb-10 px-3">
      <div className="container m-auto">
        <div className="bg-white p-5 xl:px-20 lg:w-4/4 xl:w-3/4 shadow-default m-auto rounded-2xl">
          <h2 className="font-manrope font-bold text-2xl md:text-4xl mt-5  text-center">
            Extra Information
          </h2>
          <div className="flex flex-col lg:flex-row container justify-between mt-16">
            <div className="">
              <h4 className="font-manrope font-semibold">
                Are you replacing this vehicle?
              </h4>
              <div className="mt-3">
                <button
                  className={`border  px-14 py-2 rounded-xl font-manrope mr-5 ${
                    replacing == "yes" ? "border-blue" : "border-lightgrey"
                  }`}
                  onClick={() => setReplacing("yes")}
                >
                  Yes
                </button>
                <button
                  className={`border  px-14 py-2 rounded-xl font-manrope mr-5 ${
                    replacing == "no" ? "border-blue" : "border-lightgrey"
                  }`}
                  onClick={() => setReplacing("no")}
                >
                  No
                </button>
              </div>
            </div>
            <div className="mt-10 lg:mt-0">
              <h4 className="font-manrope font-semibold">
                Does your car have a Payoff a bank or lender?
              </h4>
              <div className="mt-3">
                <button
                  className={`${
                    payoff == "yes"
                      ? "bg-green-500 text-white"
                      : "bg-green-200 text-green-700"
                  } px-14 py-2 rounded-xl font-manrope mr-5`}
                  onClick={() => setPayoff("yes")}
                >
                  Yes
                </button>
                <button
                  className={`${
                    payoff == "no"
                      ? "bg-red-500 text-white"
                      : "bg-red-200 text-red-700"
                  } px-14 py-2 rounded-xl font-manrope mr-5`}
                  onClick={() => {
                    setPayoff("no");
                    setBankName("");
                    setAmount("");
                  }}
                >
                  No
                </button>
              </div>
              <div className={`mt-4 ${payoff == "yes" ? "block" : "hidden"}`}>
                <Input
                  label="Enter the bank name"
                  placeholder="i.e Bank Of Texas"
                  value={bankName}
                  onChange={updateBankName}
                  labelClasses="text-sm"
                />
                <br />
                <br />
                <div>
                  <DollarInput
                    label="Enter The Approximate Amount"
                    placeholder="i.e $4000"
                    value={amount}
                    onChange={updateAmount}
                    type="number"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col container mt-10">
            <h4 className="font-manrope font-semibold">
              Previous Damage History
            </h4>
            <div className="flex mt-3 flex-wrap gap-4">
              <div class="flex border-2 items-center px-4 py-3 rounded-xl">
                <div class="flex items-center h-5">
                  <input
                    id="d1"
                    name="accident-history"
                    aria-describedby="helper-radio-text"
                    type="radio"
                    value="no-accidents"
                    onClick={() => setDamageHistory("No Previous Accidents")}
                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                </div>
                <div class="ms-2 text-sm">
                  <label
                    for="d1"
                    class="font-medium text-[#B0B0B0] font-manrope"
                  >
                    No Previous Accidents
                  </label>
                </div>
              </div>
              <div class="flex border-2 items-center px-4 py-3 rounded-xl">
                <div class="flex items-center h-5">
                  <input
                    id="d2"
                    name="accident-history"
                    aria-describedby="helper-radio-text"
                    type="radio"
                    value="salvage-rebuilt"
                    onClick={() => setDamageHistory("Salvage/Rebuilt")}
                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                </div>
                <div class="ms-2 text-sm">
                  <label
                    for="d2"
                    class="font-medium text-[#B0B0B0] font-manrope"
                  >
                    Salvage/Rebuilt
                  </label>
                </div>
              </div>
              <div class="flex border-2 items-center px-4 py-3 rounded-xl">
                <div class="flex items-center h-5">
                  <input
                    id="d3"
                    name="accident-history"
                    aria-describedby="helper-radio-text"
                    onClick={() => setDamageHistory("Accident History")}
                    type="radio"
                    value="accident-history"
                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                </div>
                <div class="ms-2 text-sm">
                  <label
                    for="d3"
                    class="font-medium text-[#B0B0B0] font-manrope"
                  >
                    Accident History
                  </label>
                </div>
              </div>
              <div class="flex border-2 items-center px-4 py-3 rounded-xl">
                <div class="flex items-center h-5">
                  <input
                    id="d4"
                    name="accident-history"
                    aria-describedby="helper-radio-text"
                    onClick={() => setDamageHistory("Minor Damage")}
                    type="radio"
                    value="minor-damage"
                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                </div>
                <div class="ms-2 text-sm">
                  <label
                    for="d4"
                    class="font-medium text-[#B0B0B0] font-manrope"
                  >
                    Minor Damage
                  </label>
                </div>
              </div>
              <div class="flex border-2 items-center px-4 py-3 rounded-xl">
                <div class="flex items-center h-5">
                  <input
                    id="d5"
                    name="accident-history"
                    aria-describedby="helper-radio-text"
                    type="radio"
                    onClick={() =>
                      setDamageHistory("Previous Airbag Deployed (Repaired)")
                    }
                    value="airbag-deployed"
                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                </div>
                <div class="ms-2 text-sm">
                  <label
                    for="d5"
                    class="font-medium text-[#B0B0B0] font-manrope"
                  >
                    Previous Airbag Deployed (Repaired)
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div className="flex container flex-col lg:flex-row justify-between mt-10">
            <div className="mt-3">
              <h4 className="font-manrope font-semibold ">Vehicle Condition</h4>
              <span className="mb-3 font-manrope text-xs text-[#B0B0B0]">
                Does your vehicle need any repairs?
              </span>
              <br />
              <br />
              <button
                className={`border  px-14 py-2 rounded-xl font-manrope mr-5 ${
                  repairs == "yes" ? "border-blue" : "border-lightgrey"
                }`}
                onClick={() => setRepairs("yes")}
              >
                Yes
              </button>
              <button
                className={`border  px-14 py-2 rounded-xl font-manrope mr-5 ${
                  repairs == "no" ? "border-blue" : "border-lightgrey"
                }`}
                onClick={() => setRepairs("no")}
              >
                No
              </button>
            </div>
            <div className="mt-10 lg:mt-0 flex flex-col justify-end">
              <h4 className="font-manrope font-semibold mb-3">
                Rate your vehicle’s condition
              </h4>
              <div class="flex items-center mt-2 space-x-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="39"
                  height="34"
                  viewBox="0 0 39 34"
                  fill="none"
                >
                  <g clip-path="url(#clip0_849_2240)">
                    <path
                      d="M38.1634 15.5637V13.5271C37.61 13.8039 37.0566 14.1027 36.481 14.479L31.2568 17.7552L25.4902 18.4636C22.0591 18.8842 19.9782 20.9982 19.9782 23.9202V25.6137C19.9782 28.4251 21.229 29.6979 23.9185 29.6979H25.7448V27.9381H23.9185C22.4354 27.9381 21.7491 27.1854 21.7491 25.5473V23.9202C21.7491 21.9723 23.2102 20.5112 25.7116 20.2013L30.8915 19.5815C31.6995 19.4819 32.1533 19.3159 32.6735 18.9949L37.4329 15.9954C37.6764 15.8404 37.9199 15.6965 38.1634 15.5637ZM38.1634 29.6979V27.9381H32.5629V29.6979H38.1634ZM29.2645 32.476C31.7438 32.476 33.6808 30.5391 33.6808 28.0598C33.6808 25.5916 31.7438 23.6546 29.2645 23.6546C26.7852 23.6546 24.8593 25.5916 24.8593 28.0598C24.8593 30.5391 26.7852 32.476 29.2645 32.476ZM29.2645 30.8047C27.7371 30.8047 26.5196 29.5983 26.5196 28.0598C26.5196 26.5324 27.7371 25.3259 29.2645 25.3259C30.803 25.3259 32.0205 26.5324 32.0205 28.0598C32.0205 29.5983 30.803 30.8047 29.2645 30.8047ZM7.61498 32.476C10.0943 32.476 12.0202 30.5391 12.0202 28.0598C12.0202 25.5916 10.0943 23.6546 7.61498 23.6546C5.13567 23.6546 3.19873 25.5916 3.19873 28.0598C3.19873 30.5391 5.13567 32.476 7.61498 32.476ZM7.61498 30.8047C6.07647 30.8047 4.85898 29.5983 4.85898 28.0598C4.85898 26.5324 6.07647 25.3259 7.61498 25.3259C9.14238 25.3259 10.3599 26.5324 10.3599 28.0598C10.3599 29.5983 9.14238 30.8047 7.61498 30.8047ZM0 27.9381V29.6979H4.46051V27.9381H0ZM11.1125 29.6979H12.961C15.6727 29.6979 16.8902 28.414 16.8902 25.5473V21.9833C16.8902 19.67 15.8055 18.2312 13.4037 17.4674L11.345 16.8144C8.46725 14.3573 5.21314 12.0219 0.807985 12.0219H0V13.7928H0.807985C4.07313 13.7928 6.68524 15.1431 9.89505 17.9655C10.4706 18.4636 10.7584 18.5854 11.4446 18.7956L12.8835 19.2605C14.4441 19.7586 15.1303 20.5555 15.1303 21.9833V25.5473C15.1303 27.1854 14.4441 27.9381 12.961 27.9381H11.1125V29.6979Z"
                      fill="#5D5D5D"
                    />
                    <path
                      d="M20.5862 1.66213L19.9885 4.17463L16.8562 0.964829C16.5906 0.688123 16.2364 0.89842 16.3692 1.28581L17.476 4.57309L16.0261 3.92006C15.683 3.7651 15.4284 4.01967 15.683 4.36279L16.4799 5.4032L12.7388 4.883C12.2961 4.81659 12.1633 5.25932 12.5285 5.46961L16.0593 7.61684L13.9563 8.39163C13.5689 8.53553 13.6243 8.96717 14.0338 9.0004L16.3803 9.16642L13.1926 11.3247C12.8606 11.5571 13.0045 12.0331 13.4472 11.9224L17.5092 11.0037L17.5646 14.446C17.5646 14.8002 17.9299 14.8334 18.0848 14.5899L20.6859 10.2843L25.091 14.2357C25.4341 14.5345 25.8326 14.2246 25.6223 13.8151L23.0212 8.94504L25.6776 8.15919C25.899 8.08172 25.899 7.7718 25.6776 7.68326L23.3311 6.72033L25.0246 1.92777C25.1353 1.60679 24.8143 1.34115 24.5487 1.61786L21.8037 4.19676L21.1397 1.66213C21.0511 1.30795 20.6748 1.28581 20.5862 1.66213Z"
                      fill="#3155FF"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_849_2240">
                      <rect
                        width="38.3184"
                        height="32.8042"
                        fill="white"
                        transform="translate(0 0.838623)"
                      />
                    </clipPath>
                  </defs>
                </svg>
                <input
                  type="range"
                  min="0"
                  max="5"
                  style={{ height: "2px" }}
                  value={vCondition}
                  onChange={handleSliderChange}
                  class="w-64 h-1 bg-[#5D5D5D] rounded-lg appearance-none cursor-pointer"
                />
                <div class="flex items-center justify-center w-16 h-8 bg-gray-200 rounded-full">
                  <span class="text-black font-semibold">
                    {vCondition == 5 ? "5+" : vCondition}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="container mt-10">
            <p className="font-manrope font-bold text-sm md:text-lg">
              If your vehicle has any custom modifications (like a lift kit,
              supercharger, or aftermarket exhaust) or any known issues (such as
              warning lights, body damage, accidents reported on
              Carfax/AutoCheck, hail damage, etc.), please list them below.
              Providing accurate repair estimates is vital for us to give you
              the most precise offer. The number of issues you report will help
              us determine the vehicle's value.
            </p>
            <textarea
              className="w-full border border-lightgrey rounded-lg mt-5 h-[300px] px-3 py-3 font-manrope"
              placeholder="Colour, option, trim level, leather, roof, nav, tire condition, describe any problem that we need to know."
              onChange={(e) => setDetails(e.target.value)}
              value={details}
            ></textarea>
          </div>

          <DragAndDrop
            condition={vCondition}
            replacing={replacing}
            payoff={payoff}
            repairs={repairs}
            damageHistory={damageHistory}
            details={details}
            sessionId={sessionId}
            bankName={bankName == "" ? "N/A" : bankName}
            amount={amount == "" ? "N/A" : amount}
          />
        </div>
      </div>
    </div>
  );
};

const SecondStep = ({ vehicleData, updateStage, updateSession }) => {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("+1");

  const updateFullName = (e) => {
    setFullName(e.target.value);
  };

  const updateEmail = (e) => {
    setEmail(e.target.value);
  };

  const updateMobile = (e) => {
    setMobile(e.target.value);
  };
  function isValidMobileNumber(number) {
    // Ensure the input is a string
    const numberStr = String(number);
    const cleanedNumber = numberStr.replace(/\D/g, ""); // Remove non-digit characters
    console.log(cleanedNumber);
    return (
      numberStr.startsWith("+1") &&
      (cleanedNumber.length === 10 || cleanedNumber.length === 11)
    );
  }

  const insertBasicData = () => {
    if (fullName == "" || email == "" || mobile == "") {
      toast.error("All fields are required", {
        position: "top-center",
        autoClose: "3000",
      });
    } else if (!isValidMobileNumber(mobile)) {
      toast.error("Please enter number correctly with the country code");
    } else {
      axios
        .post("https://backend.primeautovalue.com/api.php/basic-info", {
          type: "POST",
          data: {
            name: fullName,
            mobile: mobile,
            email: email,
            vin: vehicleData.vin,
            year: vehicleData.year,
            mileage: vehicleData.mileage,
            make: vehicleData.make,
            model: vehicleData.model,
          },
        })
        .then((response) => {
          if (response.data.code == 0) {
            toast.error("An error has occured!", {
              position: "top-center",
              autoClose: "3000",
            });
          } else {
            localStorage.setItem("pav__fname", fullName);
            localStorage.setItem("pav__email", email);
            localStorage.setItem(
              "pav__model",
              `${vehicleData.year} ${vehicleData.make} ${vehicleData.model}`
            );
            updateSession(response.data.id);
            updateStage(2);
          }
        });
    }
  };

  return (
    <div className="w-full bg-white py-6 px-2 mb-10">
      <div className="container flex flex-col lg:flex-row m-auto">
        <div className="w-full lg:w-1/2 p-6 lg:p-5 xl:p-16 shadow-default bg-white lg:mr-5 xl:mr-10 2xl:mr-20 rounded-3xl">
          <h2 className="font-manrope font-bold text-2xl lg:text-4xl text-center">
            Vehicle Information
          </h2>
          <div className="flex flex-col md:flex-row mt-6 md:mt-10">
            <div className="w-full  md:w-2/3 mr-5">
              <div class="relative">
                <PreFilledInput
                  label="Year, Maker and Model"
                  placeholder={"Enter Mileage"}
                  value={`${vehicleData.year} ${vehicleData.make} ${vehicleData.model}`}
                />
              </div>
            </div>
            <div className="w-full mt-5 md:mt-0 md:w-1/3">
              <div class="relative">
                <PreFilledInput
                  label="Transmission"
                  value={vehicleData.transmission}
                  helpText=""
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col md:flex-row mt-5">
            <div className="w-full md:w-3/4 mr-2">
              <div class="relative">
                <PreFilledInput
                  label="VIN Number"
                  value={vehicleData.vin}
                  helpText=""
                />
              </div>
            </div>
            <div className="w-full md:w-2/4 mt-5 md:mt-0">
              <div class="relative">
                <PreFilledInput
                  label="Mileage"
                  value={vehicleData.mileage}
                  helpText=""
                />
              </div>
            </div>
          </div>
          <div className="flex mt-3 md:mt-3 2xl:mt-10 flex-col items-center">
            <button
              className="px-6 py-5 text-white font-manrope rounded-xl bg-blue"
              onClick={() => updateStage(0)}
            >
              Start With Another Car
            </button>
            <p class="text-gray-500 text-xs mt-7 font-manrope ">
              The VIN Number will help us identify the car details.
            </p>
          </div>
        </div>
        <div className="w-full lg:w-1/2 p-6 mt-5 lg:mt-0 lg:p-5 xl:p-16 shadow-default bg-white xl:mr-5 2xl:mr-5 rounded-3xl">
          <h2 className="font-manrope font-bold text-2xl lg:text-4xl text-center">
            Your Information
          </h2>
          <div className="flex mt-6 md:mt-10">
            <div className="w-full">
              <div class="relative">
                <Input
                  label="Full Name"
                  placeholder={"Enter your full name"}
                  value={fullName}
                  onChange={updateFullName}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col md:flex-row mt-5">
            <div className="w-full md:w-1/2 mr-2">
              <div class="relative">
                <Input
                  label="Email Address"
                  placeholder={"Enter Email Address"}
                  value={email}
                  onChange={updateEmail}
                />
              </div>
            </div>
            <div className="w-full md:w-1/2 mt-5 md:mt-0">
              <div class="relative">
                <Input
                  label="Mobile Number"
                  placeholder={"Enter your Cell No."}
                  value={mobile}
                  onChange={updateMobile}
                />
              </div>
            </div>
          </div>
          <div className="flex mt-3 md:mt-3 2xl:mt-10 flex-col items-center">
            <button
              className="px-20 py-5 text-white font-manrope rounded-xl bg-blue"
              onClick={insertBasicData}
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const FirstStep = ({ updateVehicleData, updateStage }) => {
  const [vinNumber, setVinNumber] = useState("");
  const [milage, setMileage] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  let vinInputClasses = "",
    mileageInputClasses = "",
    zipCodeInputClasses = "";

  const updateVin = (e) => {
    setVinNumber(e.target.value);
  };
  const updateMileage = (e) => {
    setMileage(e.target.value);
  };
  const updateZipCode = (e) => {
    setZipCode(e.target.value);
  };

  const nextStep = () => {
    if (vinNumber == "" || milage == "" || zipCode == "") {
      toast.error("All fields are required", {
        position: "top-center",
        autoClose: 3000, // Automatically close after 3 seconds
      });
    } else {
      if (vinNumber.length > 17 || vinNumber.length < 17) {
        toast.error("VIN Number must be 17 digits", {
          position: "top-center",
          autoClose: 3000, // Automatically close after 3 seconds
        });
      } else {
        setLoading(true);
        axios
          .get(
            `https://auto.dev/api/vin/${vinNumber}?apikey=ZrQEPSkKcHJpbWVhdXRvdmFsdWVAZ21haWwuY29t`
          )
          .then((response) => {
            setLoading(false);
            let vehicleData = {
              year: response.data.years[0].year,
              model: response.data.model.name,
              make: response.data.make.name,
              transmission: response.data.transmission.transmissionType,
              mileage: milage,
              zipCode: zipCode,
              vin: vinNumber,
            };
            updateVehicleData(vehicleData);
            updateStage(1);
          });
      }
    }
  };

  return (
    <>
      {/* <Marquee>
        <div className="font-podiumbold text-3xl tracking-widest mr-4">
          <span className="text-blue">FAST</span> .<span>FAIR</span> .
          <span>BEST OFFERS</span>.
        </div>
        <div className="font-podiumbold text-3xl tracking-widest mr-4">
          <span>FAST</span> .<span className="text-blue">FAIR</span> .
          <span>BEST OFFERS</span>.
        </div>
        <div className="font-podiumbold text-3xl tracking-widest mr-4">
          <span>FAST</span> .<span>FAIR</span> .
          <span className="text-blue">BEST OFFERS</span>.
        </div>
        <div className="font-podiumbold text-3xl tracking-widest mr-4">
          <span className="text-blue">FAST</span> .<span>FAIR</span> .
          <span>BEST OFFERS</span>.
        </div>
        <div className="font-podiumbold text-3xl tracking-widest mr-4">
          <span>FAST</span> .<span className="text-blue">FAIR</span> .
          <span>BEST OFFERS</span>.
        </div>
        <div className="font-podiumbold text-3xl tracking-widest mr-4">
          <span>FAST</span> .<span>FAIR</span> .
          <span className="text-blue">BEST OFFERS</span>.
        </div>
        <div className="font-podiumbold text-3xl tracking-widest mr-4">
          <span className="text-blue">FAST</span> .<span>FAIR</span> .
          <span>BEST OFFERS</span>.
        </div>
        <div className="font-podiumbold text-3xl tracking-widest mr-4">
          <span>FAST</span> .<span className="text-blue">FAIR</span> .
          <span>BEST OFFERS</span>.
        </div>
        <div className="font-podiumbold text-3xl tracking-widest mr-4">
          <span>FAST</span> .<span>FAIR</span> .
          <span className="text-blue">BEST OFFERS</span>.
        </div>
      </Marquee> */}
      <div className="h-[750px] md:h-[680px] bg-white">
        <div
          className="w-full h-[250px] md:h-[380px] lg:h-full flex justify-center mt-0"
          style={{
            backgroundImage: `url(${HomeNewBg})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
        >
          <div className="w-full 2xl:container xl:container lg:px-6 flex flex-col justify-start items-center lg:flex-row lg:items-center relative">
            <h2
              className="text-white tracking-widest md:text-2xl lg:text-4xl xl:text-5xl font-podiumbold font-bold text-center lg:text-left mt-5 lg:mt-0 text-2xl"
              style={{ textShadow: "4px 1px 50px black" }}
            >
              <span className="text-5xl md:text-7xl lg:text-6xl xl:text-7xl">
                PRIMEAUTOVALUE
              </span>{" "}
              <br />{" "}
              <span className="text-3xl uppercase">
                Get top dollar for your vehicles
              </span>{" "}
              <br />
              <span className="text-6xl">TODAY</span>
            </h2>
            {/* Form Start */}
            <div className="py-4 px-7 2xl:py-10 xl:py-6 xl:h-[605px] xl:w-[657px] lg:h-[500px] lg:w-[500px] lg:right-6  bg-white shadow-default lg:absolute rounded-xl md:bottom-[-200px] w-11/12 bottom-[-301px] lg:bottom-auto">
              <div className="w-full mt-4">
                <h3 className="font-manrope font-bold text-xl md:text-3xl xl:text-4xl text-center">
                  Get an offer by just adding the{" "}
                  <span className="text-blue">VIN and Mileage</span>
                </h3>
                <div className="w-full mt-6">
                  <div class="relative">
                    <Input
                      helpText=""
                      label="VIN Number"
                      value={vinNumber}
                      inputClasses={vinInputClasses}
                      type="text"
                      placeholder={"Enter your VIN Number here"}
                      onChange={updateVin}
                      min="17"
                      max="17"
                    />
                  </div>
                  <p class="text-gray-500 text-xs mt-2">
                    Will help us understand car travelled by far.
                  </p>
                </div>
                {/* Second Row */}
                <div className="w-full flex mt-6">
                  <div className="w-1/2 mr-2">
                    <div class="relative">
                      <Input
                        label="Mileage"
                        placeholder={"Enter Mileage"}
                        value={milage}
                        onChange={updateMileage}
                        type="number"
                      />
                    </div>
                    <p class="text-gray-500 text-xs mt-2">
                      Will help us understand car travelled by far.
                    </p>
                  </div>
                  <div className="w-1/2">
                    <div class="relative">
                      <Input
                        label="ZIP Code"
                        placeholder={"Enter Area Zip Code"}
                        value={zipCode}
                        onChange={updateZipCode}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex mt-4 md:mt-4 2xl:mt-14 flex-col items-center">
                  <button
                    className="px-20 py-5 text-white font-manrope rounded-xl bg-blue"
                    onClick={nextStep}
                  >
                    {loading ? "Please wait..." : "Continue"}
                  </button>
                  <p class="text-gray-500 text-xs mt-2">
                    The VIN Number will help us identify the car details.
                  </p>
                </div>
              </div>
            </div>
            {/* Form End */}
          </div>
        </div>
      </div>
    </>
  );
};

const DragAndDrop = ({
  condition,
  replacing,
  payoff,
  repairs,
  damageHistory,
  details,
  sessionId,
  bankName,
  amount,
}) => {
  const [files, setFiles] = useState([]);
  const [dragging, setDragging] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleDragEnter = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setDragging(false);
  };

  const MAX_FILE_SIZE = 4 * 1024 * 1024;

  const handleDrop = (e) => {
    e.preventDefault();
    setDragging(false);
    const filesArray = Array.from(e.dataTransfer.files);

    const validFiles = [];
    filesArray.forEach((file) => {
      if (file.size <= MAX_FILE_SIZE) {
        validFiles.push(file);
      } else {
        toast.error("Image sizes should be less than 4 MB");
      }
    });

    setFiles((prevFiles) => [...prevFiles, ...validFiles]);
    console.log(validFiles);
  };

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);

    const validFiles = [];
    selectedFiles.forEach((file) => {
      if (file.size <= MAX_FILE_SIZE) {
        validFiles.push(file);
      } else {
        toast.error("Image sizes should be less than 5 MB");
      }
    });

    setFiles((prevFiles) => [...prevFiles, ...validFiles]);
    console.log(validFiles);
  };

  const deleteImage = (index) => {
    if (index > -1 && index < files.length) {
      const newFiles = [...files]; // Create a copy of the current files array
      newFiles.splice(index, 1); // Remove the item at the specified index
      setFiles(newFiles); // Update the state with the new array
    }
  };

  const sendForm = () => {
    setLoading(true);
    const formData = new FormData();

    files.forEach((file) => {
      formData.append("images[]", file);
    });
    formData.append("condition", condition);
    formData.append("replacing", replacing);
    formData.append("repairs", repairs);
    formData.append("payoff", payoff);
    formData.append("details", details);
    formData.append("session", sessionId);
    formData.append("bank_name", bankName);
    formData.append("amount", amount);
    formData.append("damageHistory", damageHistory);

    axios
      .post(
        "https://backend.primeautovalue.com/api.php/vehicle-information",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data", // Set the content type
          },
        }
      )
      .then((response) => {
        // If the response is an object
        let result = response.data; // Use response.data directly

        // Check if images_name exists and is a string
        if (result.images_name && typeof result.images_name === "string") {
          let image = result.images_name.split(",")[0];

          const params = new URLSearchParams({
            image: "https://backend.primeautovalue.com/uploads/" + image,
            fname: localStorage.getItem("pav__fname"),
            email: localStorage.getItem("pav__email"),
            car: localStorage.getItem("pav__model"),
            date: "12th November 2024",
          }).toString();

          return axios.get(
            `https://backend.primeautovalue.com/email-test/index.php?${params}`
          );
        } else {
          throw new Error("images_name is not valid");
        }
      })
      .then((response) => {
        navigate("/finish");
      })
      .catch((error) => {
        console.error("Error uploading files:", error);
      });
    setTimeout(() => navigate("/finish"), 6000);
  };

  return (
    <>
      <div class="w-full mt-5">
        <div class="mb-2 flex items-center">
          <h2 class="text-xl font-semibold text-gray-800 font-manrope mr-3">
            Upload Photos
          </h2>
          <p class="text-xs text-gray-500 font-manrope mt-1">
            Front, Side & Back Picture of Car
          </p>
        </div>
        {files.length > 0 ? (
          <>
            <div className="w-full border-2 p-5">
              <div className="w-full flex flex-wrap gap-4">
                {files.map((file, index) => (
                  <div key={index} className="relative">
                    <img
                      src={close}
                      className="absolute right-0 cursor-pointer"
                      onClick={() => {
                        deleteImage(index);
                      }}
                    />
                    <div
                      className="w-[180px] h-[230px] rounded-lg"
                      style={{
                        backgroundImage: `url(${URL.createObjectURL(file)})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                      }}
                    ></div>
                  </div>
                ))}
              </div>
              {files.length < 6 ? (
                <div className="w-full flex justify-end items-center h-10">
                  <label className="cursor-pointer">
                    <span class="text-white bg-blue px-4 py-2 rounded-lg">
                      Add Image
                    </span>
                    <input
                      type="file"
                      class="hidden"
                      multiple
                      onChange={handleFileChange}
                    />
                  </label>
                </div>
              ) : (
                ""
              )}
            </div>
          </>
        ) : (
          <div
            class="border-2 border-dashed border-gray-300 rounded-lg p-6 flex items-center justify-center"
            onDragEnter={handleDragEnter}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
          >
            <label class="flex flex-col items-center justify-center w-full h-32 bg-white rounded-lg cursor-pointer hover:bg-gray-50">
              <div>
                <span class="text-white bg-blue px-4 py-2 rounded-xl mr-3">
                  Choose files
                </span>
                <span class="text-gray-500 mt-2 font-manrope">
                  or drag them here.
                </span>
              </div>
              <input
                type="file"
                class="hidden"
                multiple
                onChange={handleFileChange}
              />
            </label>
          </div>
        )}
      </div>
      <div className="flex justify-center mt-5">
        {loading ? (
          <button
            className="px-20 py-5 text-white font-manrope rounded-xl mt-2 m-auto bg-blue"
            disabled
          >
            Please wait...
          </button>
        ) : (
          <button
            className="px-20 py-5 text-white font-manrope rounded-xl mt-2 m-auto bg-blue"
            onClick={sendForm}
          >
            Finish
          </button>
        )}
      </div>
    </>
  );
};

export default Home;
