import React, { useState, useEffect } from "react";
import CarCard from "./CarCard";
import DemoImage from "../images/demo_image.png";
import { useParams } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";

const ViewCarDetails = () => {
  const [cars, setCars] = useState([]);
  const [chats, setChats] = useState([]);
  const [loading, setLoading] = useState(true);
  const [noteLoading, setNoteLoading] = useState(false);

  const [userList, setUserList] = useState([]);

  const [showImage, setShowImage] = useState("");

  const [note, setNote] = useState("");

  const userData = jwtDecode(localStorage.getItem("pav_token"));
  const { id } = useParams();

  useEffect(() => {
    getCarDetail();
  }, []);

  const getUserList = () => {
    axios
      .get("https://backend.primeautovalue.com/api.php/users", {
        type: "get",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("pav_token")}`,
        },
      })
      .then((response) => {
        setUserList(response.data);
        setLoading(false);
      });
  };

  const updateNote = (car_id) => {
    setNoteLoading(true);
    axios
      .post(
        "https://backend.primeautovalue.com/api.php/add-note",
        {
          car_id: car_id,
          note: note,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("pav_token")}`,
          },
        }
      )
      .then((response) => {
        if (response.data.code == 1) {
          toast.success("Note added.");
          setChats((prevItems) => [
            ...prevItems,
            {
              chat_from: userData.user_id,
              chat_car: cars[0].car_id,
              chat_message: note,
              full_name: userData.name,
              date_added: "Today", // This formats the date as "YYYY-MM-DD"
            },
          ]);
          setNote("");
          setNoteLoading(false);
        }
      });
  };

  const getCarDetail = () => {
    axios
      .get("https://backend.primeautovalue.com/api.php/cars/" + id, {
        type: "get",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("pav_token")}`,
        },
      })
      .then((response) => {
        setCars(response.data.details);
        setChats(response.data.chat);

        if (userData.rank == 1) {
          getUserList();
        } else {
          setLoading(false);
        }
      });
  };

  const assignCar = (e, i) => {
    axios
      .post(
        "https://backend.primeautovalue.com/api.php/assign-query/" +
          e +
          "/" +
          i,
        {
          user_id: e,
          car_id: i,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("pav_token")}`,
          },
        }
      )
      .then((response) => {
        if (response.data.code == 1) {
          toast.success("Assigned Successfully!");
        }
      });
  };

  function formatDate(dateString) {
    const date = new Date(dateString);

    const options = { year: "numeric", month: "short", day: "numeric" };
    const formattedDate = date.toLocaleDateString("en-US", options);

    // Extract day and format it with suffix
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.getFullYear();

    // Function to get the suffix for the day
    function getDaySuffix(day) {
      if (day > 3 && day < 21) return "th"; // Catch 11th-13th
      switch (day % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    }

    return `${day}${getDaySuffix(day)} ${month} ${year}`;
  }

  return (
    <>
      {loading ? (
        "Please Wait..."
      ) : (
        <>
          <div
            style={{ height: "100vh" }}
            className="container m-auto mt-1 px-4 md:px-10 overflow-auto"
          >
            {showImage !== "" ? (
              <div
                className="absolute flex justify-center items-center top-0 left-0 bottom-0 right-0 z-10"
                onClick={() => setShowImage("")}
                style={{
                  backdropFilter: `blur(10.15px)`, // You can simplify the value
                  background: `rgba(0, 0, 0, 0.69)`,
                }}
              >
                <img
                  src={`https://backend.primeautovalue.com/uploads/${showImage}`}
                  width="90%"
                />
              </div>
            ) : (
              ""
            )}
            <ToastContainer />
            <div className="w-full h-10 flex justify-between items-center">
              <h2 className="font-manrope font-semibold text-2xl">
                {loading
                  ? "Loading"
                  : `${cars[0].year} ${cars[0].model} ${cars[0].make}`}
              </h2>
            </div>
            <section className="w-full mt-2">
              <CarCard
                car={cars[0]}
                userData={userData}
                userList={userList}
                assignCar={assignCar}
              />
            </section>
            <section className="flex flex-col lg:flex-col xl:flex-row 2xl:flex-row">
              <div className="w-full xl:w-2/5 bg-white border px-10 py-10">
                <div>
                  <h3 className="font-manrope font-bold text-2xl">
                    Extra Information
                  </h3>
                </div>
                <div className="mt-4">
                  <label className="font-manrope font-semibold text-[#171717B2] text-md">
                    Are you replacing this vehicle?
                  </label>
                  <h4 className="font-manrope font-bold text-black text-xl mb-4 transform capitalize">
                    {cars[0].replacing}
                  </h4>
                  <div className="w-full h-[0.5px] bg-[#E2E2E2]"></div>
                </div>
                <div className="mt-4">
                  <label className="font-manrope font-semibold text-[#171717B2] text-md">
                    Payoff/Lease
                  </label>
                  <h4 className="font-manrope font-bold text-black text-xl capitalize">
                    {cars[0].payoff}
                  </h4>
                  <span className="font-manrope font-semibold text-[#171717B2] text-xs">
                    Bank : {cars[0].bank_name}
                  </span>
                  <br />
                  <span className="font-manrope font-semibold text-[#171717B2] text-xs">
                    Approx Amount:{" "}
                    {cars[0].amount == null ? "N/A" : "$" + cars[0].amount}
                  </span>
                  <div className="w-full h-[0.5px] bg-[#E2E2E2] mt-4"></div>
                </div>
                <div className="mt-4">
                  <label className="font-manrope font-semibold text-[#171717B2] text-md">
                    Vehicle’s Condition
                  </label>
                  <div className="mt-3 mb-3">
                    <span className="px-5 py-1 rounded-full bg-[#0000000F] font-manrope font-bold">
                      {cars[0].rating}
                    </span>
                  </div>
                  <span className="px-3 py-1 text-xs font-manrope border border-[#717171] rounded-full">
                    {cars[0].previous_damage}
                  </span>
                  <div className="w-full h-[0.5px] bg-[#E2E2E2] mt-4"></div>
                </div>
                <div className="mt-4">
                  <label className="font-manrope font-semibold text-[#171717B2] text-md">
                    Additional Details
                  </label>
                  <br />
                  <span className="font-manrope font-semibold text-[#171717B2] text-xs">
                    {cars[0].details}
                  </span>
                  <div className="w-full h-[0.5px] bg-[#E2E2E2] mt-4"></div>
                </div>
                <div className="mt-4">
                  <label className="font-manrope font-semibold text-[#171717B2] text-md">
                    Notes
                  </label>
                  <br />
                  <div className="font-manrope font-semibold text-[#171717B2] text-xs">
                    {chats.map((chat) => (
                      <p
                        className="w-full break-words leading-tight"
                        key={chat.id}
                      >
                        {chat.full_name} : {chat.chat_message} <br />
                        <small>
                          {chat.date_added == "Today"
                            ? "Today"
                            : formatDate(chat.date_added)}
                        </small>
                      </p>
                    ))}
                  </div>
                  <div className="w-full h-[0.5px] bg-[#E2E2E2] mt-4"></div>
                  <div className="flex mt-3">
                    <input
                      className="bg-white border px-2 border-blue text-sm py-2 rounded-md flex-1"
                      placeholder="Add note..."
                      value={note}
                      onChange={(e) => setNote(e.target.value)}
                    />
                    <button
                      className="bg-blue text-white px-2 ml-2 rounded-md text-sm font-manrope"
                      onClick={() => updateNote(cars[0].car_id)}
                    >
                      Add Note
                    </button>
                  </div>
                </div>
              </div>
              <div className="px-5 py-8 border rounded-lg flex-1 lg:ml-10 flex flex-wrap justify-center items-center gap-1">
                {cars[0].photos.split(",").map((photo, index) => (
                  <div
                    className="w-[260px] h-[270px] bg-center bg-cover rounded-2xl"
                    id="image-link"
                    style={{
                      backgroundImage: `url(https://backend.primeautovalue.com/uploads/${photo.trim()})`,
                    }}
                    onClick={() => setShowImage(photo.trim())}
                  ></div>
                ))}
              </div>
            </section>
          </div>
        </>
      )}
    </>
  );
};

export default ViewCarDetails;
