import React, { useState } from "react";
import { Input } from "../components/Input";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const AddUser = () => {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const updateFullName = (e) => setFullName(e.target.value);
  const updateEmail = (e) => setEmail(e.target.value);
  const updatePassword = (e) => setPassword(e.target.value);
  const updateConfirmPassword = (e) => setConfirmPassword(e.target.value);

  const navigate = useNavigate();

  const createUser = () => {
    if (
      fullName == "" ||
      email == "" ||
      password == "" ||
      confirmPassword == ""
    ) {
      toast.error("All fields are required!");
    } else {
      if (password != confirmPassword) {
        toast.error("Password must match.");
      } else {
        axios
          .post(
            "https://backend.primeautovalue.com/api.php/create-user",
            {
              fullName: fullName,
              email: email,
              password: password,
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("pav_token")}`,
              },
            }
          )
          .then((response) => {
            if (response.data.code === 1) {
              toast.success("User created!");
              setFullName("");
              setEmail("");
              setPassword("");
              setConfirmPassword("");
            }
          })
          .catch((error) => {
            console.error("There was an error creating the user!", error);
          });
      }
    }
  };

  return (
    <div className="h-screen w-full flex flex-col justify-center items-center px-3">
      <ToastContainer />
      <div className="bg-white p-5 border max-w-[650px] py-8">
        <h2 className="font-prodiumMiheer font-bold text-3xl md:text-4xl lg:text-5xl text-blue">
          CREATE NEW ACCOUNT
        </h2>
        <div className="mt-5">
          <Input
            label="Full Name"
            placeholder={"Enter Full Name"}
            onChange={updateFullName}
            type="text"
            value={fullName}
          />
          <br />
          <br />
          <Input
            label="Email Address"
            placeholder={"Enter Email Address"}
            onChange={updateEmail}
            value={email}
          />
          <br />
          <br />
          <Input
            label="Password"
            placeholder={"Enter Password"}
            type="password"
            onChange={updatePassword}
            value={password}
          />
          <br />
          <br />
          <Input
            label="Confirm Password"
            placeholder={"Confirm Password"}
            type="password"
            onChange={updateConfirmPassword}
            value={confirmPassword}
          />
        </div>
        <div className="flex justify-center">
          <button
            className="px-20 py-5 text-white font-manrope rounded-xl mt-5 m-auto bg-blue"
            onClick={createUser}
          >
            Create User
          </button>
        </div>
      </div>
      <span
        className="font-manrope text-sm mt-3 cursor-pointer"
        onClick={() => navigate("/admin")}
      >
        Back To Dashboard
      </span>
    </div>
  );
};

export default AddUser;
