import React, { useEffect, useState } from "react";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";

import TermsBg from "./images/terms_bg.png";

const ToC = () => {
  const [mainHeight, setMainHeight] = useState(0);

  useEffect(() => {
    const updateMainHeight = () => {
      const navbar = document.getElementById("navbar").offsetHeight;
      const footer = document.getElementById("footer").offsetHeight;

      // Calculate the main height: 100vh - navbarHeight - footerHeight
      const height = window.innerHeight - navbar - footer;
      setMainHeight(height);
    };

    // Set initial height
    updateMainHeight();

    // Update height on window resize
    window.addEventListener("resize", updateMainHeight);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", updateMainHeight);
    };
  }, []);

  return (
    <>
      <div className="flex flex-col justify-between">
        <Navbar />
        {/* Use inline style for dynamic height */}
        <div style={{ height: `${mainHeight}px` }} className="w-full flex">
          <div
            className="w-1/2 h-full bg-cover bg-center bg-no-repeat hidden lg:block relative"
            style={{ backgroundImage: `url(${TermsBg})` }}
          >
            <h1 className="text-white font-prodiumMiheer lg:text-6xl 2xl:text-7xl capitalize tracking-widest absolute bottom-10 left-10">
              TERMS AND
              <br />{" "}
              <span className="lg:text-6xl 2xl:text-8xl capitalize">
                CONDITIONS
              </span>
            </h1>
          </div>
          <div className="w-2/2 flex justify-center items-center">
            <div
              className="container px-10 py-10 overflow-auto"
              style={{ height: `${mainHeight - 100}px` }}
            >
              <h2 className="font-manrope font-bold text-3xl">
                Terms and Conditions.
              </h2>
              <p className="font-manrope mt-5 text-sm mb-5 max-w-[800px]">
                1. Introduction Welcome to Prime Auto Value. By accessing our
                website and using our services, you agree to comply with and be
                bound by the following terms and conditions. If you do not agree
                with any part of these terms, please do not use our website.{" "}
                <br />
                <br /> 2. Services Provided Prime Auto Value offers a platform
                for users to sell their vehicles by submitting their VIN number,
                filling out a form with vehicle details, uploading pictures, and
                sending an inquiry to receive the best price offers. <br />
                <br /> 3. User Responsibilities Users must provide accurate,
                current, and complete information during the submission process.
                Users are solely responsible for maintaining the confidentiality
                of any personal information submitted on our website. Any
                fraudulent, misleading, or inappropriate use of our website is
                strictly prohibited.
                <br />
                <br /> 4. Valuation and Offers Valuations and offers provided by
                Prime Auto Value are estimates based on the information supplied
                by the user. These offers do not constitute a guaranteed
                purchase or price commitment by Prime Auto Value.
                <br />
                <br />
                5. Third-Party Services Prime Auto Value may engage third-party
                services for car valuations and other related services. We are
                not responsible for the actions, terms, or privacy policies of
                these third-party providers. <br />
                <br />
                6. Limitation of Liability Prime Auto Value will not be liable
                for any direct, indirect, incidental, or consequential damages
                arising from your use of our website or reliance on any
                valuation or offer provided.
                <br />
                <br /> 7. Dispute Resolution Any disputes arising from the use
                of Prime Auto Value’s services shall be resolved through
                arbitration in accordance with the laws of [Insert Your
                Jurisdiction Here]. <br />
                <br /> 8. Modifications to Terms Prime Auto Value reserves the
                right to modify or update these Terms and Conditions at any time
                without prior notice. Continued use of the website after changes
                are made constitutes acceptance of the new terms. <br />
                <br /> 9. Governing Law These terms are governed by the laws of
                [Insert Your Jurisdiction Here], and you agree to submit to the
                exclusive jurisdiction of the courts in that jurisdiction. 10.
                Contact Information For any questions or concerns regarding
                these Terms and Conditions, please contact us at
                info@primeautovalue.com.
              </p>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default ToC;
